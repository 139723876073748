import * as React from "react";
import '../styles/index.scss';
import '../styles/secondSection.scss';
import '../styles/thirdSection.scss';
import '../styles/footer.scss';
import '../styles/formSection.scss';
import '../styles/datePicker.scss';
import '../styles/teamSection.scss';


import logo from '../images/fidente_final.svg';
import { HeaderLogo } from "../components/HeaderLogo";
import { Header } from "../components/header/Header";

import columnBackground from '../images/test.jpg';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar } from '@fortawesome/free-solid-svg-icons'

import preventionLogo from '../images/icons/check-up.png';
import childrenLogo from '../images/icons/orthodontic.png';
import endoLogo from '../images/icons/endo.png';
import perioLogo from '../images/icons/perio.png';
import implantLogo from '../images/icons/implant.png';
import extractionLogo from '../images/icons/extraction.png';
import conservationLogo from '../images/icons/seal.png';
import openingLogo from '../images/icons/opening.png';

import locationLogo from '../images/icons/dentist.png';
import phoneLogo from '../images/icons/phone.png';
import { SEO } from "../components/seo/seo";
import Forms from "../components/form/Form";

// import katrien, layla, joao, sasja, laura and gabriele
import katrien from '../images/people/katrien.jpeg';
import layla from '../images/people/layla.jpeg';
import joao from '../images/people/joao.jpeg';
import sasja from '../images/people/sasja.jpg';
import laura from '../images/people/laura.jpeg';
import gabriele from '../images/people/gabriele.jpeg';
import daniele from '../images/people/daniela.jpg';
import sengul from '../images/people/sengul.jpeg';

const PEOPLE = [
  {
    name: 'Gabriele Gugyte',
    title: 'Algemeen Tandarts',
    image: gabriele,
    nr: "32029004-001"
  },
  {
    name: 'João Prata De Figueiredo',
    title: 'Algemeen Tandarts',
    image: joao,
    nr: "31827084-001"
  },
  {
    name: "Daniela Albuquerque",
    title: "Algemeen Tandarts",
    image: daniele,
    nr: "31817978-001"
  },
  {
    name: 'Laura Verberckmoes',
    title: 'Mondhygiëniste',
    image: laura,
    nr: "10130637"
  },
  {
    name: 'Katrien Van Roey',
    title: 'Tandartsassistente',
    image: katrien,
  },

  {
    name: 'Layla Azirar',
    title: 'Tandartsassistente',
    image: layla,
  },
  {
    name: 'Sengul Ulker',
    title: 'Tandartsassistente',
    image: sengul,
  },
  {
    name: 'Sasja Vandendriessche',
    title: 'Manager',
    image: sasja,
  },
]


const HeroBackground = () => {
  return (
    <div className="hero-background">

      <div className="hero-contrast" style={{ backgroundImage: `url('${columnBackground}')` }}>
        {/* <div className="top"></div>
        <div className="bottom"></div> */}

      </div>
    </div>
  )
}

const Tooth = () => {
  return (
    <div className="tooth">
      <svg x="0px" y="0px" viewBox="0 0 1000 1000" style={{ zIndex: 100, marginBottom: '15px' }}>
        <defs>
          <clipPath id="toothPath">
            <path d="M657.3,957.8c-13.5-18-25.3-41.3-36.9-66.9l0,0c-23.1-51-45.8-111.1-71.7-149l0,0c-17.4-25.7-34.7-38.9-51-38.5l0,0
		c-4.5,0-9.3,0.9-14.7,3l0,0c-76.1,27.5-107.5,149.8-150.7,215.8l0,0c-14.6,21.7-33.5,39.7-60,39.9l0,0c-9,0-18.2-2.2-27.4-6.4l0,0
		C194.4,931.4,143.5,843,99.1,722.3l0,0C55.7,601.7,22.8,449.4,22.8,301.1l0,0c0-43.1,2.8-86,9-127.6l0,0
		c0.3-5.3,2.7-36.3,21.5-70.4l0,0c20-36.7,60.9-76.5,134.7-86.4l0,0c8.4-1.2,16.7-1.7,24.7-1.7l0,0c59.6,0.1,106.5,29.8,149.3,57.1
		l0,0c43,27.7,82.1,52.8,125,52.6l0,0c12.3,0,25.2-2,39.1-6.5l0,0C617.8,88.4,704.9,11,800.3,10l0,0c34.6-0.1,69.9,11.2,104.4,39
		l0,0c53,47.2,72.3,135.7,72.6,240.4l0,0c-0.6,234.1-100.3,553.5-228,682.4l0,0c-12.6,11.5-26.4,18.3-40.6,18.3l0,0
		C686.8,989.7,670.6,975.5,657.3,957.8L657.3,957.8z" />
          </clipPath>
        </defs>

        <image href="https://i.imgur.com/K8pGbNn.jpg" preserveAspectRatio="xMidYMid slice" width="1000" height="1000" style={{ clipPath: 'url(#toothPath)' }} />
      </svg>
    </div>

  )
}

const Hero = () => {
  return (
    <div className="hero">
      <HeroBackground />
      <div className="hero-content">
        <div className="small-logo">
          <HeaderLogo />
        </div>
        <h1 className="classic light">Wij heten u van harte welkom in onze nieuwe tandartsenpraktijk te Nieuwkerken-Waas</h1>
        <div className="action-text">
          <p className="text light">Maak zeker een afspraak als nieuwe patiënt voor uw intake en jaarlijkse check-up.</p>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '25px', flexWrap: 'wrap' }}>
          <a href="tel:032886431" className="text light" style={{ margin: 0, marginRight: '10px', marginTop: '10px' }}>Bel ons op 03 288 64 31</a>
          <p className="text light" style={{ margin: 0, marginRight: '10px', marginTop: '10px' }}> of</p>
          <a href="#form-section" className="button light" style={{ textDecoration: 'none', marginTop: '10px' }}><p className="text">Maak online afspraak</p> <div className="icon-wrapper"><FontAwesomeIcon icon={faCalendar} /></div></a>
        </div>
        <Tooth />
      </div>
    </div>
  )
}

const SecondSection = () => {
  return (
    <div className="second">
      <div style={{ position: 'relative' }}>
        <div className="back-hook">
          <p className="text">"</p>
        </div>
        <h2 className="classic">Professionaliteit en dienstverlening van hoge kwaliteit staan bij ons centraal. Onze professionals werken hun kennis en kunde constant bij en staan steeds klaar om de beste dienstverlening mogelijk na te streven.</h2>
      </div>
    </div>
  )
}


const SERVICES = [
  {
    icon: preventionLogo,
    title: 'Check-up & Preventie',
    description: "Kom voor jouw (half)jaarlijkse controle en voorkom problemen in de mond."
  },
  {
    icon: conservationLogo,
    title: 'Conserverende behandeling',
    description: "Soms is preventie niet genoeg. Wanneer je toch een gaatje krijgt staan we klaar om uw tand zo goed mogelijk te herstellen en beschermen."
  },
  {
    icon: endoLogo,
    title: 'Spoedendodontie',
    description: "Wanneer het nodig is kunnen wij de tandzenuw behandelen om je van jouw pijn te verlossen."
  },
  {
    icon: perioLogo,
    title: 'Parodontologie',
    description: "Een tand staat niet alleen! Soms hebben de structuren rond je tand ook extra zorg nodig. Behandelingen om jouw ontstoken tandvlees aan te pakken horen hier thuis."
  },
  {
    icon: extractionLogo,
    title: 'Chirurgie',
    description: "(Wijsheids)tanden verwijderen en andere ingrepen kan in veel gevallen bij ons in de praktijk gebeuren. Complexere gevallen verwijzen wij op een gepaste manier door!"
  },
  {
    icon: implantLogo,
    title: 'Implantaten',
    description: "Een getrokken tand betekent niet dat u geen volle lach meer kan hebben. In veel gevallen is het mogelijk om een implantaat te plaatsen waar dan een kroon op gezet kan worden."
  },
  {
    icon: childrenLogo,
    title: 'Tandheelkunde voor kinderen',
    description: "Het gebit verdient zorg van jongs af. De juiste begeleiding zorgt voor een gezond (melk)gebit."
  },
]

const Service = ({ icon, title, description }) => {
  return (
    <div className="service-wrapper">
      <div className="icon-wrapper">
        <img src={icon} />
      </div>
      <p className="title classic-bold">{title}</p>
      <p className="desc text">
        {description}
      </p>
    </div>
  )
}

const ThirdSection = () => {
  return (
    <div className="third">
      <div className="section-content">
        <div className="top-row">
          <h2 className="classic">Jouw lach is onze zorg.</h2>
        </div>
        <div className="services">
          {SERVICES.map(service => <Service {...service} />)}
        </div>
      </div>
    </div>
  )
}

const FooterSection = () => {
  return (
    <div className="footer" id="footer">
      <div className="information-wrapper">
        <div className="information-box">
          <div className="info-logo-column">
            <div className="info-logo-wrapper">
              <img src={phoneLogo} />
            </div>
          </div>
          <div className="info-text-column">
            <p className="info-title classic">Afspraak maken <br />of meer informatie?</p>
            <a href="tel:032886431" className="info-phone text-bold">03 288 64 31</a>
            <a href="mailto: info@fidente.be" className="info-email text">info@fidente.be</a>
          </div>
        </div>
        <div className="information-box">
          <div className="info-logo-column">
            <div className="info-logo-wrapper">
              <img src={locationLogo} />
            </div>
          </div>
          <div className="info-text-column">
            <p className="info-title classic">Waar kan je ons <br /> vinden?</p>
            <p className="text address">Meesterstraat 155</p>
            <p className="text address">9100 Sint-Niklaas</p>
          </div>
        </div>
        <div className="information-box">
          <div className="info-logo-column">
            <div className="info-logo-wrapper">
              <img src={openingLogo} />
            </div>
          </div>
          <div className="info-text-column">
            <p className="info-title classic">Wanneer zijn we <br /> bereikbaar?</p>
            <div className="day-time-row">
              <p className="text-bold day">Maandag</p>
              <p className="text">10:00 - 18:00</p>
            </div>
            <div className="day-time-row">
              <p className="text-bold day">Dinsdag</p>
              <p className="text">10:00 - 18:00</p>
            </div>
            <div className="day-time-row">
              <p className="text-bold day">Woensdag</p>
              <p className="text">10:00 - 18:00</p>
            </div>
            <div className="day-time-row">
              <p className="text-bold day">Donderdag</p>
              <p className="text">10:00 - 18:00</p>
            </div>
            <div className="day-time-row">
              <p className="text-bold day">Vrijdag</p>
              <p className="text">10:00 - 18:00</p>
            </div>
            <div className="day-time-row">
              <p className="text-bold day">Zaterdag</p>
              <p className="text">Gesloten</p>
            </div>
            <div className="day-time-row">
              <p className="text-bold day">Zondag</p>
              <p className="text">Gesloten</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const FormSection = () => {
  return (
    <div className="form-section classic" id="form-section">
      <Forms />
    </div>
  )
}

const InformationFooter = () => {
  return (
    <div className="information-footer">
      <p className="text">Tandartspraktijk - Fidente BV - BE 0793.627.571</p>
      <p className="text">Gabriele Gugyte - Gabriele Enterprises BV met N° BE 0773.967.750 - Algemeen Tandarts - Riziv N° 32029004-001</p>
    </div>
  )
}

const TeamSection = () => {
  return (
    <div className="team">
      <div className="team-content">
        <h2 className="classic">Ons team staat voor u klaar.</h2>
        <div className="team-wrapper">
          {
            PEOPLE.map(person => (
              <div className="person-container">
                <div className="person-wrapper">
                  <div className="person-image-wrapper">
                    <img src={person.image} />
                  </div>
                  <p className="person-name classic-bold">{person.name}</p>
                  <p className="person-title text">{person.title}</p>
                  {person.nr && <p className="person-title text">Riziv Nummer - {person.nr}</p>}
                </div>
              </div>

            ))
          }

        </div>
      </div>
    </div>
  )
}


const IndexPage = () => {
  return (
    <main className="main-wrapper">
      <Header />
      {/* <img src={logo}/> */}
      <Hero />
      <SecondSection />
      <TeamSection />
      <ThirdSection />
      <FormSection />
      <FooterSection />
      <InformationFooter />
    </main>
  )
}

export default IndexPage

export const Head = () => (
  <>
    <script dangerouslySetInnerHTML={{
      __html: `(function(h,o,t,j,a,r){
        h.hj = h.hj || function () { (h.hj.q = h.hj.q || []).push(arguments) };
      h._hjSettings={hjid:3308514,hjsv:6};
      a=o.getElementsByTagName('head')[0];
      r=o.createElement('script');r.async=1;
      r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
      a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`}}>

    </script>
    <link rel="stylesheet" href="https://use.typekit.net/fzc1hha.css"></link>
    <SEO title={'Fidente'} pathname={''} description='Wij heten u van harte welkom in onze tandartspraktijk in Nieuwkerken-Waas.' />
  </>
)
