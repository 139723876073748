import React from "react"

export const SEO = ({ title, description, pathname, children }) => {

    const IMAGE = 'https://i.imgur.com/9rGwTvK.png'
    const SEO_URL = 'https://www.fidente.be/'

  return (
    <>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="image" content={IMAGE} />
      <meta name="og:image" content={IMAGE} />
      <meta name="og:title" content={title} />
      <meta name="og:url" content={SEO_URL + pathname} />
      <meta name="og:description" content={description} />
      <meta name="twitter:card" content={description} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:url" content={SEO_URL + pathname} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={IMAGE} />
      {/* <link rel="icon" href="data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'><text y='0.9em' font-size='90'>👤</text></svg>" /> */}
      {children}
    </>
  )
}