import React, { useEffect, useState } from "react";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import { DayPicker } from 'react-day-picker';
import { getCalendar, getDay } from "../../api";
import Loader from "../loading/Loader";
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

const PHONE_REGEX = new RegExp('^(((\\+|00)32[ ]?(?:\\(0\\)[ ]?)?)|0){1}(4(60|[789]\\d)\\/?(\\s?\\d{2}\\.?){2}(\\s?\\d{2})|(\\d\\/?\\s?\\d{3}|\\d{2}\\/?\\s?\\d{2})(\\.?\\s?\\d{2}){2})$')
const FREE_DAYS = [new Date("2023-01-09"), new Date("2023-01-30")]
const AVAILABLE_WEEKS = 28

const Calendar = ({ disabledDays, daySelect, selected }) => {
    const [disabled, setDisabled] = useState([])

    useEffect(() => {
        let now = new Date()
        setDisabled([{ before: new Date(), after: now.setDate(now.getDate() + AVAILABLE_WEEKS * 7) }, ...disabledDays])
    }, [disabledDays])

    return (
        <div className="day-picker">
            <DayPicker
                weekStartsOn={1}
                selected={selected}
                mode="single"
                disabled={disabled}
                onSelect={(day, selectedDay, activeModifiers, e) => daySelect(day, selectedDay, activeModifiers, e)}
            />
        </div>
    )
}

export default function Forms() {
    // const recaptchaRef = React.createRef();

    // Input Change Handling
    const [submitted, setSubmitted] = useState(null)
    const [inputs, setInputs] = useState({
        firstName: "",
        lastName: "",
        email: "",
        tel: "",
        message: "",
        reason: "first",
        day: "",
        time: ""
    });

    const [disabledDays, setDisabledDays] = useState([])
    const [selectedDay, selectDay] = useState()
    const [availabilities, setAvailabilities] = useState([])
    const [slide, setSlide] = useState(0)
    const [loading, setLoading] = useState(true)


    const [isComplete, setCompletion] = useState(false)
    const [isUserComplete, setUserComplete] = useState(false)
    const [isCalendarComplete, setCalendarComplete] = useState(false)

    const [notification, setNotification] = useState('')


    const { executeRecaptcha } = useGoogleReCaptcha()
    const [token, setToken] = useState('')

    const [selectedTime, selectTime] = useState("")

    const handleOnChange = (event) => {
        event.persist();
        let text = event.target.value;

        if (event.target.id === 'tel') {
            text = text.replace(' ', '')
            text = text.replace(/[^0-9+]/g, '')
        }

        setInputs((prev) => ({
            ...prev,
            [event.target.id]: text,
        }));
    };

    const onDaySelect = (day, selectedDay, activeModifiers, e) => {

        let today = new Date()

        if ((day.getDate() === today.getDate()) && (day.getMonth() === today.getMonth()) && (day.getFullYear() === today.getFullYear())) {
            return
        }
        selectDay(day);
        setLoading(true);
        setInputs((prev) => ({
            ...prev,
            time: '',
            day
        }))

        getDay(day.getDate(), day.getMonth() + 1, day.getFullYear())
            .then(res => {
                setLoading(false)
                if (res.schedule && res.schedule.availabilities) {
                    setAvailabilities(res.schedule.availabilities.map(item => item[0]))
                }
            })
    }

    const onTimeSelect = (time) => {
        setInputs((prev) => ({
            ...prev,
            time
        }))
    }

    const checkComplete = () => {
        if (inputs.firstName && inputs.lastName && inputs.email && inputs.tel) {
            if (inputs.day) {
                if (inputs.time) {
                    if (inputs.reason === 'other' && inputs.message) {
                        setNotification('')
                        return setCompletion(true)
                    } else if (inputs.reason !== 'other' && inputs.reason) {
                        setNotification('')
                        return setCompletion(true)
                    }
                }
            }
        }
        return setCompletion(false)
    }

    const checkUserData = () => {
        if (inputs.firstName && inputs.lastName && inputs.email && inputs.tel) {

            if (inputs.reason === 'other' && inputs.message) {
                setNotification('')
                return setUserComplete(true)
            } else if (inputs.reason !== 'other' && inputs.reason) {
                return setUserComplete(true)

            }
        }
        return setUserComplete(false)
    }

    const checkCalendar = () => {
        if (inputs.time && inputs.day) {
            return setCalendarComplete(true)
        }
        return setCalendarComplete(false)
    }

    useEffect(() => {
        checkComplete()
        checkUserData()
        checkCalendar()
    }, [inputs])

    useEffect(() => {
        setLoading(true)
        getCalendar().then(
            (res) => {
                setLoading(false)
                let calendar = res.calendar;
                let disabled = calendar.map(item => {
                    if (item[1] === false) {
                        return new Date(item[0])
                    }
                }).filter(item => item)
                disabled = [...FREE_DAYS, ...disabled];
                setDisabledDays(disabled)
            }
        )
    }, [])

    const onNextSlide = (slide) => {
        switch (slide) {
            case 1:
                if (isUserComplete) {
                    setSlide(slide)
                    return
                } else {
                    setNotification('Vul alle velden in aub')
                }
            case 2:
                if (isCalendarComplete) {
                    setSlide(slide)
                    return
                } else {
                    setNotification('Gelieve een dag en tijd te selecteren aub')
                }
            default:
                break
        }

    }


    const onConfirm = () => {
        handleOnSubmit();
    }

    // Server State Handling

    const handleOnSubmit = async (event) => {

        // Prevent default
        event && event.preventDefault();

        // Check ReCaptcha
        if (!executeRecaptcha) {
            return
        }

        setLoading(true)

        const result = await executeRecaptcha('homepage');
        setToken(result)

        // Get isodate
        let day = inputs.day;
        let time;
        let hoursMinutes;

        if (inputs.time) {
            time = inputs.time;
            hoursMinutes = time.split(':')
        } else {
            time = ['00', '00']
        }


        let month = day.getMonth() + 1
        if (month.toString().length == 1) {
            month = "0" + month;
        }

        let currentDay = day.getDate()
        if (currentDay.toString().length == 1) {
            currentDay = "0" + currentDay;
        }

        let isoTime = `${day.getFullYear()}-${month}-${currentDay}T${hoursMinutes[0]}:${hoursMinutes[1]}:00`
        isoTime = isoTime.split('.')[0]

        // Process inputs
        let data = {
            firstName: inputs.firstName,
            lastName: inputs.lastName,
            email: inputs.email,
            tel: inputs.tel,
            message: inputs.message,
            reason: inputs.reason,
            isoTime
        }

        console.log(JSON.stringify(data))

        axios({
            method: "POST",
            url: "https://agenda.fidente.be/appointment",
            data: { ...data, 'g-recaptcha-response': token }
        })
            .then((r) => {
                setLoading(false)
                setSubmitted('success')
            })
            .catch((r) => {
                axios({
                    method: "POST",
                    url: "https://formbold.com/s/6vZ06",
                    data: { ...inputs, 'g-recaptcha-response': token },
                })
                    .then((r) => {
                        setLoading(false)
                        setSubmitted('success')
                    })
                    .catch((r) => {
                        setLoading(false)
                        setSubmitted('error')
                    });
            })


    };

    return (
        <>
            {
                submitted === 'success' && <h2 className="classic-bold" style={{ textAlign: 'center', margin: '0 20px' }}>Perfect, we laten je zo snel mogelijk iets weten!</h2>
            }
            {
                submitted === 'error' && <h2 className="classic-bold" style={{ textAlign: 'center', margin: '0 20px' }}>Oeps, er is iets misgelopen, probeer later opnieuw! Bel ons voor een afspraak op 03/288 64 31</h2>
            }
            {submitted !== 'success' && submitted !== 'error' &&
                <>
                    <h2 className="classic-bold" style={{ textAlign: 'center' }}>{slide === 0 ? "Een afspraak?" : slide === 1 ? "Kies een datum" : "Wacht op bevesting!"}</h2>
                    <p className="subtitle error">{notification}</p>
                    <div className={`form-wrapper show-slide-${slide}`}>
                        <form onSubmit={handleOnSubmit} className={`slide-0`}>
                            <div className="form-group">
                                <label>Voornaam</label>
                                <input
                                    onChange={handleOnChange}
                                    value={inputs.firstName}
                                    id="firstName"
                                    name="firstName"
                                    data-hj-allow
                                />
                            </div>
                            <div className="form-group">
                                <label>Achternaam</label>
                                <input
                                    onChange={handleOnChange}
                                    value={inputs.lastName}
                                    id="lastName"
                                    name="lastName"
                                    data-hj-allow
                                />
                            </div>
                            <div className="form-group">
                                <label>Telefoonnummer</label>
                                <input
                                    onChange={handleOnChange}
                                    value={inputs.tel}
                                    id="tel"
                                    autoComplete="tel"
                                    type="tel"
                                    name="tel"
                                    placeholder="03 288 64 31"
                                    data-hj-allow
                                    pattern="/^(((\+|00)32[ ]?(?:\(0\)[ ]?)?)|0){1}(4(60|[789]\d)\/?(\s?\d{2}\.?){2}(\s?\d{2})|(\d\/?\s?\d{3}|\d{2}\/?\s?\d{2})(\.?\s?\d{2}){2})$/"
                                />
                            </div>
                            <div className="form-group">
                                <label>Email</label>
                                <input
                                    onChange={handleOnChange}
                                    value={inputs.email}
                                    id="email"
                                    type="email"
                                    name="email"
                                    placeholder="Email"
                                    data-hj-allow
                                />
                            </div>
                            {/* <div className="form-group">
                                <label>Welke dag?</label>
                                <select name="days" id="day" onChange={handleOnChange}>
                                    <option value="monday">Maandag</option>
                                    <option value="tuesday">Dinsdag</option>
                                    <option value="thursday">Donderdag</option>
                                    <option value="friday">Vrijdag</option>
                                    <option value="friday">Zaterdag</option>
                                </select>
                            </div> */}
                            <div className="form-group">
                                <label>Type afspraak</label>
                                <select name="reason" id="reason" onChange={handleOnChange}>
                                    <option value="first">Eerste bezoek</option>
                                    <option value="checkup">Check-up</option>
                                    <option value="other">Andere</option>
                                </select>
                            </div>
                            {inputs.reason === 'other' && <div className="form-group">
                                <label>Beschrijf de reden van uw afspraak</label>
                                <textarea
                                    onChange={handleOnChange}
                                    value={inputs.message}
                                    id="message"
                                    name="message"
                                    data-hj-allow
                                    placeholder="Afgebroken tand, tandpijn, ..."
                                />
                            </div>}
                            <div className="form-group">
                                {/* <ReCAPTCHA
                                    ref={recaptchaRef}
                                    sitekey=""
                                /> */}
                            </div>
                            {/* <button className={'button submit-button text-bold'} type="submit">Verzenden</button> */}
                            <div className="button-wrapper">
                                <button className={`button next m-hide text-bold ${isUserComplete ? '' : 'disabled'}`} onClick={() => onNextSlide(1)} type="button">Kies datum & tijd</button>
                            </div>
                        </form>
                        <div className="picker-wrapper slide-1">
                            <Calendar disabledDays={disabledDays} daySelect={onDaySelect} selected={selectedDay} />
                            <div className="pill-wrapper">
                                {!loading && availabilities.map((item) => <div onClick={() => onTimeSelect(item)} className={`avail-pill ${inputs.time === item ? 'selected' : ''}`}><p>{item}</p></div>)}
                                {!selectedDay && !loading && availabilities && availabilities.length === 0 && <p style={{ textAlign: 'center' }}>Kies een beschikbare dag en daarna komen de beschikbare tijdssloten hier.</p>}
                                {selectedDay && !loading && availabilities && availabilities.length === 0 && <p>Geen afspraken beschikbaar</p>}
                            </div>
                            {loading && <Loader />}
                            <div className="button-wrapper m-hide">
                                <button type="button" className="button previous" onClick={() => setSlide(0)}>Vorige</button>
                                <button type="button" className={`button next ${isCalendarComplete ? '' : 'disabled'}`} onClick={() => onNextSlide(2)}>Volgende</button>
                            </div>
                            {<div className="m-show disclaimer-wrapper">
                                <p className="disclaimer classic">Onze afspraken worden allemaal gecontroleerd voor ze ingeboekt worden. Wij versturen u zo snel mogelijk een bevestigingsemail. Mocht er verduidelijking nodig zijn dan bellen wij u op.</p>
                                <div className="button-wrapper">
                                    <button type="button" className={`button ${isComplete ? '' : 'disabled'}`} style={{ marginLeft: 'auto', marginRight: 'auto' }} onClick={onConfirm}>Bevestigen</button>
                                </div>
                            </div>
                            }

                        </div>
                        <div className="slide-2">
                            <p className="disclaimer classic">Onze afspraken worden allemaal gecontroleerd voor ze ingeboekt worden. Wij versturen u zo snel mogelijk een bevestigingsemail. Mocht er verduidelijking nodig zijn dan bellen wij u op.</p>
                            {loading && <Loader />}
                            {!loading && <div className="button-wrapper">
                                <button type="button" className={`button`} onClick={onConfirm} style={{ marginLeft: 'auto', marginRight: 'auto' }}>Bevestigen</button>
                            </div>}
                        </div>
                        <div className="temporarily-unavailable">
                            <h1>De online boekingsmodule is tijdelijk offline.</h1>
                            <p className="text">Bel ons op <a href="tel:032886431">03 288 64 31</a> of mail ons op <a href="mailto: info@fidente.be" class="info-email text">info@fidente.be</a></p>
                        </div>
                    </div> </>
            }

        </>

    );
}