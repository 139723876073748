const CALENDAR_API = 'https://agenda.fidente.be/'

const get = (url) => {
    return fetch(url, {
        credentials: 'include'
    })
    .then(res => res.json())
    .catch(error => null)
}

const getCalendar = () => {
    return get(CALENDAR_API + 'calendar')
}

const getDay = (day, month, year) => {
    return get(CALENDAR_API + `?day=${day}&month=${month}&year=${year}`)
}

export {
    getCalendar,
    getDay
}