import * as React from "react";
import { HeaderLogo } from "../HeaderLogo";

import './Header.scss';

export const Link = ({text}) => {
  return (
    <div className="link-wrapper">
      <a className="text-bold">{text}</a>
    </div>
  )
}

export const Header = () => {
  return (
    <div className="header">
        <HeaderLogo/>
        {/* <Link text={"Nieuwe Patiënten"}/> */}
    </div>
  )
}

