import * as React from "react";

export const HeaderLogo = () => {
  return (
    <div className="header-logo">
        <p className="classic">Fí<span>dente</span></p>
    </div>
  )
}

